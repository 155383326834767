import request from '@/utils/request'

// 获取告警设置信息
export function findAlarmSetting() {
  return request({
    url: `/customer/agent_alarms/find_alarm_setting`,
    method: 'get'
  })
}

// 刷新告警绑定二维码
export function refreshAlarmSettingBindQrcode() {
  return request({
    url: `/customer/agent_alarms/alarm_setting/refresh_bind_qrcode`,
    method: 'get'
  })
}

// 告警申请列表
export function findAlarmBindApplies(params) {
  return request({
    url: `/customer/agent_alarms/alarm_bind_applies`,
    method: 'get',
    params: params
  })
}

// 修改告警申请状态
export function changeAlarmAppliesStatus(data) {
  return request({
    url: `/customer/agent_alarms/alarm_bind_applies/status`,
    method: 'post',
    data
  })
}
