<template>
  <div>
    <search-alarm-bind-apply @submit="submitSearch" />
    <a-row type="flex" class="iotplt-margin-bottom-15px">
      <a-col
        :span="4"
      >
        <a-button-group>
          <a-popover placement="bottom" trigger="click">
            <template slot="content">
              <img :src="qrcode" style="width: 200px; height: 200px;">
              <div style="text-align:center;">微信扫码</div>
            </template>
            <a-button type="primary">
              告警接收绑定码
            </a-button>
          </a-popover>
          <a-button type="primary" @click="refreshBindQrcode">
            刷新
          </a-button>
        </a-button-group>
      </a-col>
    </a-row>

    <div class="iotplt-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="confirmToPass"
        >通过</a-button>

        <a-button
          type="primary"
          @click="confirmToReject"
        >拒绝/失效</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loadingTable"
      :pagination="false"
      row-key="id"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
    >
      <div slot="head_img" slot-scope="head_img">
        <img :src="head_img" style="width:40px">
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findAlarmSetting, findAlarmBindApplies, refreshAlarmSettingBindQrcode, changeAlarmAppliesStatus } from '@/api/agent_alarm'
import SearchAlarmBindApply from '@/views/agent_alarms/Search'

export default {
  name: 'AlarmsSetting',
  components: {
    Pagination,
    SearchAlarmBindApply
  },
  data() {
    return {
      data: [],
      qrcode: '',
      loadingAlarmSetting: true,
      loadingTable: true,
      selectedRowKeys: [],
      pagination: {
        total_count: 0
      },
      query: {
        per_page: 10
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  computed: {
    agentId() {
      if (this.$route.query.agent_id) {
        return parseInt(this.$route.query.agent_id)
      }

      return this.$store.getters.userAgentId
    },

    agentType() {
      return parseInt(this.$store.getters.userAgentType)
    },

    columns() {
      return [
        {
          title: '名称',
          dataIndex: 'full_name'
        },
        {
          title: '头像',
          dataIndex: 'head_img',
          scopedSlots: { customRender: 'head_img' }
        },
        {
          title: '时间',
          dataIndex: 'created_at'
        },
        {
          title: '状态',
          dataIndex: 'status'
        }
      ]
    }
  },
  created() {
    this.findAlarmSettingData()
    this.fetchData()
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    warningSelectObjects() {
      this.$warning({
        title: '请选择操作对象',
        content: ''
      })
    },

    findAlarmSettingData() {
      this.loadingAlarmSetting = true
      findAlarmSetting().then((res) => {
        if (res.code === 0) {
          if (res.data.id === 0) {
            this.refreshBindQrcode()
          } else {
            this.qrcode = res.data.qrcode
          }
        }

        this.loadingAlarmSetting = false
      })
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.selectedRowKeys = []
      this.loadingTable = true
      findAlarmBindApplies(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loadingTable = false
      })
    },

    refreshBindQrcode() {
      refreshAlarmSettingBindQrcode().then((res) => {
        if (res.code === 0) {
          this.qrcode = res.data.qrcode
        }
      })
    },

    confirmToPass() {
      const vm = this
      if (vm.selectedRowKeys.length === 0) {
        this.warningSelectObjects()
      } else {
        this.$confirm({
          title: '确定通过吗?',
          content: '',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            changeAlarmAppliesStatus({ ids: vm.selectedRowKeys, status: 1 }).then((res) => {
              vm.fetchData()
            })
          }
        })
      }
    },

    confirmToReject() {
      const vm = this
      if (vm.selectedRowKeys.length === 0) {
        this.warningSelectObjects()
      } else {
        this.$confirm({
          title: '确定拒绝或失效吗?',
          content: '',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            changeAlarmAppliesStatus({ ids: vm.selectedRowKeys, status: 2 }).then((res) => {
              vm.fetchData()
            })
          }
        })
      }
    }
  }
}
</script>

